import "../dashboard/search/test.css";

import * as Yup from "yup";

import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React from "react";

import DataTable from "react-data-table-component";
import Footer from "./footer";
import Navbar from "../dashboard/navBar";
import SearchBar from "./search/search-bar";
import Select from 'react-select';
import { apiBaseUrl } from "../../config.example";
import axios from "axios";
import classnames from "classnames";
import { cogoToastHelper } from "../../_helpers/cogoToastHelper";
import { connect } from "react-redux";
import { favouriteService } from "../../_services/favourite.service";
import i18next from "i18next";
import makeAnimated from 'react-select/lib/animated';
import { profile } from "../../_actions/profile.action";
import { sourcesService } from "../../_services/sources.service";
import { stripePaymentActivated } from "../../config.example";
import { userService } from "../../_services/user.service";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
      justifyContent: 'center'
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontWeight: "bold",
      fontSize: 14,
      justifyContent: 'center'
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      fontSize: 14,
      justifyContent: 'center'
    },
  },
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      toggleComment: false,
      toggleFavComment: false,
      toggleFavDelete: false,
      activeTab: "1",
      commentss: [],
      profile: [],
      profilePicture: "",
      professions: [],
      domain: "",
      comment: "",
      favComment: "",
      commentsFilter: {},
      subscribedSources: [],
      favourisedSources: [],
      professionalArea: [],
      sources: [],
      subscriptionIDSelected: '',
      favouriteDomainSelected: '',
      submitting: true,
      selectedFile: null,
      specialistTitle: [],
      domaincheckMsg: '',
      domainCheck: false,
      anonymousChecked: "",
      professionalAreaList: [], /* all options specialistTitle*/
      fav: 0,
      columns: [
        {
          name: "Comment",
          selector: "userComment",
          sortable: true,
          cell: (row) => row.userComment,
        },
      ],
      subscribed_columns: [
        {
          name: [i18next.t("domain")],
          selector: "domain",
          sortable: true,
        },
        {
          name: [i18next.t("Comment")],
          selector: "isDefault",
          sortable: true,
          cell: (row) => this.state.commentsFilter['subs' + row.subscriptionId],
        },
        {
          name: [i18next.t("ddhsources")],
          selector: "isDefault",
          sortable: true,
          cell: (row) =>
            row.isDefault == true ? [i18next.t("ddhsouce")] : [i18next.t("sources")],
        },
        {
          name: "Status",
          selector: "status",
          sortable: true,
          cell: (row) => userService.sourcesStatus(row.sourceStatus),
        },
        {
          cell: (row) => (row.isDefault != 1) ? (
            <Button
              className="btn btn-success"
              onClick={() => this.toggle_Comment_Model(row)}
            >
              <i className="fa fa-upload icons">{i18next.t("Comment")}</i>
            </Button>
          ) : (<></>),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          cell: (row) => (
            this.commentRow(row)
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
      ],
      favourite_columns: [
        {
          name: [i18next.t("domain")],
          cell: (row) => (
            this.favouriteDomainColumn(row)
          ),
          ignoreRowClick: true
        },
        {
          name: [i18next.t("Articles")],
          cell: (row) => (
            this.favouriteArticlesColumn(row)
          ),
          ignoreRowClick: true
        },
        {
          name: [i18next.t("Comment")],
          selector: "comment",
          sortable: true,
        },
        {
          cell: (row) => (
            <Button className="btn btn-success" onClick={() => this.toggleFavouriteComment(row)}>
              <i className="fa fa-upload icons">{i18next.t("Comment")}</i>
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          cell: (row) => (
            <Button className="btn btn-danger" onClick={() => this.toggleFavouriteDelete(row)}>
              <i className="fa fa-upload icons">{i18next.t("delete")}</i>
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true
        }
      ]
    };
  }

  componentDidMount() {
    profile();
    this.getSubscriptions();
    this.getProfessions();
    this.getFavourites();
  }

  componentDidUpdate() {
    if (this.state.profile.length === 0) {
      if (this.props.profile.id !== undefined) {
        this.setState({ profile: this.props.profile });
        this.setState({ profilePicture: this.props.profile.profilePicture });
      }

      if (this.props.profile.specialistTitle != undefined) {

        var list = this.props.profile.specialistTitle;

        // Prevent empty options
        if (list.length !== 0){
          list = list.split(",");

          var dataList = list.map(area => {
            let option = {
              value: area,
              label: area,
            }
            return option;
          });
        }
        this.setState({ specialistTitle: dataList });
        this.setState({ anonymousChecked: this.props.profile.anonymous == 1 ? "checked" : "" });
      };
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  commentRow(row) {
    if (row.label === "Subscribed") {
      return <Button
        className="btn btn-danger"
        onClick={() => this.unsubscribe(row)}
      >
        <i className="fa fa-upload icons">{i18next.t("unsubscribe")}</i>
      </Button>;
    } else {
      return <Button
        className="btn btn-success"
        onClick={() => this.subscribeToSource(row)}
      >
        <i className="fa fa-upload icons">{i18next.t("subscribe")}</i>
      </Button>;
    }
  }

  favouriteDomainColumn(row) {
    var domain = (row.domain.startsWith("http") ? row.domain : "http://" + row.domain);
    return (<a href={domain}>{row.domain}</a>);
  }

  favouriteArticlesColumn(row) {
    var articleList = row.articles ? row.articles.split(", ") : "";
    return (
      <div>
        {row.articles ?
          articleList.map((articleLink) =>
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
              <div onClick={() => this.onClickRemoveArticle(row, articleLink)} style={{ float: 'left', paddingRight: '15px' }}><i className="fa fa-trash icons" style={{ color: 'red' }}></i></div>
              <a href={articleLink}><div style={{ paddingLeft: '15px' }}>{articleLink}</div></a>
            </div>
          )
          :
          <div></div>
        }
      </div>
    );
  }

  favouriteButtonColumn(row) {
    return <div onClick={() => this.onClickRemoveFavourite(row)}><i className="fa fa-star icons" style={{ color: 'orange' }}></i></div>
  }

  onClickRemoveArticle(row, articleLink) {
    this.removeArticle(row, articleLink);
  }

  onClickRemoveFavourite(row) {
    this.removeFavourite(row)
  }

  toggleProps() {
    if (this.state.toggle === true) {
      this.setState({ toggle: false });
    }
    if (this.state.toggle === false) {
      this.setState({ toggle: true });
    }
  }

  commentsFilter(id) {
    //console.log(this.state.commentsFilter);
  }

  toggle_Comment_Model(data) {
    if (this.state.toggleComment === true) {
      this.setState({ toggleComment: false });
    }
    if (this.state.toggleComment === false) {
      this.setState({ toggleComment: true });
    }
    this.setState({ subscriptionIDSelected: data.subscriptionId });
  }

  toggleFavouriteComment(data) {
    this.setState({favComment: data.comment});

    if (this.state.toggleFavComment === true) {
      this.setState({ toggleFavComment: false });
    }
    if (this.state.toggleFavComment === false) {
      this.setState({ toggleFavComment: true });
    }

    this.setState({ favouriteDomainSelected: data.domain });
  }

  toggleFavouriteDelete(data) {
    if (this.state.toggleFavDelete === true) {
      this.setState({ toggleFavDelete: false });
    }
    if (this.state.toggleFavDelete === false) {
      this.setState({ toggleFavDelete: true });
    }
    this.setState({ favouriteDomainSelected: data.domain });
  }

  onDomainSubject(data) {
    //check if the domain name already exists
    var dataParams = {
      url: data
    };
    sourcesService.sourceCheck(dataParams).then(
      (response) => {
        var msg = "";
        if (response === true)
          msg = [i18next.t("urlexists")];

        this.setState({ domaincheckMsg: msg, domainCheck: response });
      },
      (error) => {

      }
    );
    this.setState({ domain: data });
  }

  enableDisableBtn(data) {
    if (data.sourceStatus == 1) {
      return (
        <Button
          className="btn btn-danger"
          onClick={() => this.DisableSources(data)}>
          <i className="fa fa-upload icons">{i18next.t("Disable")}</i>
        </Button>
      );
    } else {
      return (
        <Button
          className="btn btn-success"
          onClick={() => this.DisableSources(data)}
        >
          <i className="fa fa-upload icons">{i18next.t("Enable")}</i>
        </Button>
      );
    }
  }

  getProfessions() {
    this.setState({ submitting: true });
    sourcesService.professions().then(
      (response) => {

        var dataList = response.map(area => {
          let option = {
            value: area.id,
            label: area.professionalTitle,
          }
          return option;
        });

        this.setState({ professions: dataList });
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );

    sourcesService.professionalArea().then(
      (response) => {
        this.setState({ professionalArea: response });

        var dataList = response.map(area => {
          let option = {
            value: area.id,
            label: area.area,
          }
          return option;
        });
        this.setState({ professionalAreaList: dataList });

      },
      (error) => {
        this.setState({ submitting: false });
      }
    );
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ profilePicture: URL.createObjectURL(event.target.files[0]) })
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleAreasChange = (selectedOption) => {
    this.setState({ specialistTitle: selectedOption });
  }

  toggleAnonymous = (selectedOption) => {
    this.setState({ anonymousChecked: this.state.anonymousChecked == "checked" ? "" : "checked"})
  }

  handleTitleChange = (selectedOption) => {
    const profile = { ...this.state.profile };
    profile.academicTitle = selectedOption.label;
    this.setState({ profile });
  }

  handleChangeProfile = (e) => {
    var prof = { ...this.state.profile };
    prof[e.target.name] = e.target.value;
    this.setState({ profile: prof });
  };

  getSubscriptions() {
    this.setState({ submitting: true });

    sourcesService.getComments().then((commentsList) => {
      var commentsFilter = {};
      for (let c in commentsList) {
        var comment = commentsList[c];
        commentsFilter['subs' + comment.subscriptionId] = comment.userComment;
      }
      this.setState({ commentss: commentsList, commentsFilter: commentsFilter });
    });

    var listOfSources = [];
    sourcesService.getSubscriptions().then((sourcesList) => {
      if (
        typeof sourcesList.unsubscribed == "object" &&
        typeof sourcesList.unsourced
      ) {

        for (var source in sourcesList.subscribed) {
          var sourceDetails = sourcesList.subscribed[source];
          sourceDetails.label = "Subscribed";
          listOfSources.push(sourceDetails);
        }

        for (var source in sourcesList.defaultSource) {
          const sourceDetails = sourcesList.defaultSource[source];
          sourceDetails.label = "Subscribed";
          listOfSources.push(sourceDetails);
        }

        for (var source in sourcesList.unsourced) {
          var sourceDetails = sourcesList.unsourced[source];
          sourceDetails.label = userService.sourcesStatus(sourceDetails.status);
          listOfSources.push(sourceDetails);
        }

        this.setState({ subscribedSources: listOfSources });
      }

      this.setState({ submitting: false });
    });
  }

  subscribe(source) {
    this.setState({ submitting: true });
    var formData = {
      SourceUrl: source.domain,
      UserID: "",
    };

    sourcesService.NewSource(formData).then(
      (response) => {
        this.getSubscriptions();
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );
  }

  subscribeToSource(source) {
    this.setState({ submitting: true });
    var ids = [source.sourceId];
    var formData = {
      ids: ids,
      isDefault: source.isDefault
    };

    sourcesService.subscribe(formData).then(
      (response) => {
        this.getSubscriptions();
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );
  }

  unsubscribe(source) {
    this.setState({ submitting: true });
    var ids = [source.sourceId];
    var formData = {
      ids: ids,
      isDefault: source.isDefault
    };

    sourcesService.UnSubscribeSource(formData).then(
      (response) => {
        this.getSubscriptions();
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );
  }

  setComment(comment) {
    this.setState({ comment: comment.target.value });
  }

  getFavourites() {
    favouriteService.getFavourites().then((favouriteList) => {
      this.setState({
        favourisedSources: favouriteList
      })
    });
  };

  removeFavourite(row) {
    this.setState({ submitting: true });
    var config = {
      method: "get",
      url: apiBaseUrl + '/Search/RemoveFavouriteSource/' + row.domain + '',
      headers: {},
    };
    axios(config).then(() => this.getFavourites());

    row.isFavourite = false;
    this.setState({ submitting: false });
  };

  removeArticle(row, articleLink) {
    this.setState({ submitting: true });
    axios.post(apiBaseUrl + '/Search/RemoveFavouriteArticle', {
      domain: row.domain,
      article: articleLink
    }).then(() => this.getFavourites());

    row.isFavouriteArticle = false;
    this.setState({ submitting: false });
  };

  setFavComment(favComment) {
    this.setState({ favComment: favComment.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <SearchBar />
        <div className="row">
          <div className="col-12">
            <br></br>
            <div class="s130">
              <div class="col-10">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}>
                      {i18next.t("profile")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        this.toggleProps();
                      }}
                    >
                      {i18next.t("requestsource")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      {i18next.t("Favourites")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={this.state.activeTab}
                  className="profileContainer"
                >
                  <TabPane tabId="1">
                    <Formik
                      initialValues={{
                        userName: this.state.profile.userName,
                        mobileNumber: this.state.profile.mobileNumber,
                        specialistTitle: this.state.profile.specialistTitle,
                        academicTitle: this.state.profile.academicTitle,
                        employer: this.state.profile.employer,
                        telephoneNumber: this.state.profile.telephoneNumber,
                        firstName: this.state.profile.firstName,
                        anonymous: this.state.profile.anonymous
                      }}
                      enableReinitialize={true}
                      validationSchema={
                        Yup.object().shape({
                          firstName: Yup.string()
                            .min(2,[i18next.t("firstnameshouldbe2chars")])
                            .required([i18next.t("firstnamerequired")]),
                          userName: Yup.string()
                            .min(5, [i18next.t("usernamelonger5chars")])
                            .max(15, [i18next.t("usernamelong")])
                            .required([i18next.t("usernamerequired")]),
                          employer: Yup.string()
                            .required([i18next.t("employerrequired")]),
                          mobileNumber: Yup.string()
                            .required( [i18next.t("mobilenumrequired")])
                        })
                      }

                      onSubmit={(data, actions) => {

                        // check if specialistTitle is set
                        if(this.state.specialistTitle == null || this.state.specialistTitle.length == 0) {
                          cogoToastHelper.error("Select your professional area");
                          actions.setSubmitting(false);
                        }
                        else
                        {
                          // gather all selected specialistTitle
                          var listAreas = this.state.specialistTitle.map(area => {
                            return area.label;
                          });

                          let formData = new FormData();
                          formData.append("userName", data.userName);
                          formData.append("mobileNumber", data.mobileNumber);
                          formData.append("telephoneNumber", data.telephoneNumber);
                          formData.append("specialistTitle", listAreas.join());
                          formData.append("academicTitle", data.academicTitle);
                          formData.append("employer", data.employer);
                          formData.append("firstName", data.firstName);
                          formData.append("anonymous", this.state.anonymousChecked == "checked" ? true : false);
                          formData.append("File", this.state.selectedFile);
                          userService.updateUser(formData)
                            .then(
                              response => {
                                profile();
                                actions.setSubmitting(false);
                                cogoToastHelper.success([i18next.t("Profileupdatedsuccessfully")]);
                              },
                              error => {
                                cogoToastHelper.error((error.message))
                                actions.setSubmitting(false);
                              }
                            );
                        }
                      }
                    }
                      render={({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form
                          action=""
                          method="post"
                          className="newmessageForm"
                        >
                          <Row className="labelTitle">

                            <Col xs="12" lg="6">
                              <br />
                              <div className="profilePic">
                                <img src={this.state.profilePicture} alt="" width="220" />
                              </div><br />
                              <FormGroup>
                                <input
                                  type="file"
                                  onChange={this.onFileChange}
                                  className="fileUpload"
                                  ref={(input) => (this.inputElement = input)} />
                              </FormGroup>
                            </Col>
                            <Col xs="12" lg="6">
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  placeholder={i18next.t("firstname")}
                                  tag={Field}
                                  onChange={this.handleChangeProfile}
                                  invalid={errors.firstName && touched.firstName}
                                  valid={errors.firstName && touched.firstName}
                                />
                                <FormFeedback>
                                  {errors.firstName}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  readOnly
                                  value={this.state.profile.lastName}
                                  placeholder="Last Name"
                                />
                                <FormFeedback>
                                  {errors.lastName}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="email"
                                  id="email"
                                  readOnly
                                  value={this.state.profile.email}
                                  placeholder="Email Address"
                                />
                                <FormFeedback>
                                  {errors.email}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="userName"
                                  id="userName"
                                  placeholder={i18next.t("userName")}
                                  tag={Field}
                                  onChange={this.handleChangeProfile}
                                  readOnly
                                  invalid={errors.userName && touched.userName}
                                  valid={errors.userName && touched.userName}
                                />
                                <FormFeedback>
                                  {errors.userName}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Select
                                  name="specialistTitle"
                                  id="specialistTitle"
                                  closeMenuOnSelect={false}
                                  components={makeAnimated()}
                                  isMulti='true'
                                  value={this.state.specialistTitle }
                                  options={this.state.professionalAreaList}
                                  onChange={this.handleAreasChange.bind(this)}
                                />
                                <FormFeedback>
                                  {errors.specialistTitle}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="employer"
                                  id="employer"
                                  placeholder={i18next.t("employer")}
                                  tag={Field}
                                  onChange={this.handleChangeProfile}
                                  invalid={errors.employer && touched.employer}
                                  valid={errors.employer && touched.employer}
                                />
                                <FormFeedback>
                                  {errors.employer}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Select
                                  id="academicTitle"
                                  name="academicTitle"
                                  closeMenuOnSelect={true}
                                  components={makeAnimated()}
                                  value={{ label: this.state.profile.academicTitle }}
                                  options={this.state.professions}
                                  onChange={this.handleTitleChange}
                                />
                                <FormFeedback>
                                  {errors.academicTitle}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  type="text"
                                  placeholder={i18next.t("mobilenumber")}
                                  tag={Field}
                                  onChange={this.handleChangeProfile}
                                  invalid={errors.mobileNumber && touched.mobileNumber}
                                  valid={errors.mobileNumber && touched.mobileNumber}
                                />
                                <FormFeedback>
                                  {errors.mobileNumber}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  id="telephoneNumber"
                                  name="telephoneNumber"
                                  type="text"
                                  placeholder={i18next.t("telephonnumber")}
                                  tag={Field}
                                  onChange={this.handleChangeProfile}
                                  invalid={errors.telephoneNumber && touched.telephoneNumber}
                                  valid={errors.telephoneNumber && touched.telephoneNumber}
                                />
                                <FormFeedback>
                                  {errors.telephoneNumber}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  id="GLN"
                                  name="GLN"
                                  type="text"
                                  readOnly
                                  value={this.state.profile.gln}
                                  placeholder={i18next.t("glnNumber")}
                                />
                                <FormFeedback>
                                  {errors.gln}
                                </FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                  <Input
                                    id="anonymous"
                                    name="anonymous"
                                    type="checkbox"
                                    value={this.state.anonymous}
                                    checked={this.state.anonymousChecked}
                                    onClick={this.toggleAnonymous}
                                  />
                                {i18next.t("anonymous")}
                                  <FormFeedback>
                                    {errors.anonymous}
                                  </FormFeedback>
                                </FormGroup>


                              {!isSubmitting && (
                                <Col sm="12" md="12" style={{ padding: 0 }}>
                                  <Button
                                    className="cardButton "
                                    block
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {i18next.t("updateProfile")}
                                  </Button>
                                  <br></br>
                                  <br></br>
                                  <a href="/Subscription" style={{ color: "#2d2762" }}>
                                    {stripePaymentActivated === "true" ? [i18next.t("press2unsubscribe")] : ""}</a>
                                </Col>
                              )}
                              {isSubmitting && <center><Spinner color="success" /></center>}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    {this.state.submitting == true && (
                      <center>
                        <Spinner color="success" />
                      </center>
                    )}
                    {this.state.submitting == false && (
                      <DataTable
                        columns={this.state.favourite_columns}
                        data={this.state.favourisedSources}
                        customStyles={customStyles}
                        selectableRowsComponentProps={{ inkDisabled: true }}
                        pagination={true}
                        striped={true}
                        paginationPerPage={25}
                        noHeader={true}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <br></br>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Footer />
          </div>
        </div>

        <Modal isOpen={this.state.toggle} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.toggleProps()}>
            {i18next.t("requestnewsource")}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                SourceUrl: this.state.domain,
                UserId: "",
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={(data, actions) => {
                let formData = {
                  UserId: "",
                  SourceUrl: this.state.domain,
                };

                if (this.state.domainCheck === false) {
                  sourcesService.NewSource(formData).then(
                    (response) => {
                      cogoToastHelper.success([i18next.t("Sourcesrequestedsuccessfully")]);
                      actions.setSubmitting(false);
                      this.setState({ domain: "" });
                      this.setState({ toggle: false });
                    },
                    (error) => {
                      actions.setSubmitting(false);
                      this.setState({ toggle: false });
                    }
                  );
                }
              }}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form action="" method="post" className="messageForm1">
                  {(this.state.domaincheckMsg !== "") &&
                    <small className="errorMessage">{this.state.domaincheckMsg}</small>
                  }
                  <FormGroup>
                    <Input
                      type="input"
                      id="domain"
                      name="domain"
                      placeholder={i18next.t("domainurl")}
                      onChange={(e) =>
                        this.onDomainSubject(`${e.target.value}`)
                      }
                      invalid={errors.domain && touched.domain}
                      valid={this.state.domain}
                    />
                    <FormFeedback>{errors.domain}</FormFeedback>
                  </FormGroup>

                  {!isSubmitting && (
                    <Col sm="12" md="3" style={{ padding: 0 }}>
                      <Button
                        className="cardButton"
                        block
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {i18next.t("submit")}
                      </Button>
                    </Col>
                  )}
                  {isSubmitting && <Spinner color="success" />}
                </Form>
              )}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.toggleComment} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.setState({ toggleComment: false })}>
            {i18next.t("Comment")}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                comment: this.state.comment,
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={(data, actions) => {

                if (this.state.comment !== "") {
                  this.setState({ submitting: true });
                  var postData = {
                    subscriptionID: this.state.subscriptionIDSelected,
                    comment: this.state.comment
                  };

                  sourcesService.subscriptioncomment(postData).then(
                    (response) => {
                      actions.setSubmitting(false);
                      this.setState({ toggleComment: false });
                      this.getSubscriptions();
                    },
                    (error) => {
                      this.setState({ submitting: false });
                    }
                  );
                } else {
                  actions.setSubmitting(false);
                }
              }}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form action="" method="post" className="messageForm1">

                  <FormGroup>
                    <Input
                      type="input"
                      id="comment"
                      name="comment"
                      placeholder={i18next.t("Comment")}
                      onChange={(e) =>
                        this.setComment(e)
                      }
                      invalid={errors.comment && touched.comment}
                      valid={this.state.comment}
                    />
                    <FormFeedback>{errors.comment}</FormFeedback>
                  </FormGroup>

                  {!isSubmitting && (
                    <Col sm="12" md="3" style={{ padding: 0 }}>
                      <Button
                        className="cardButton"
                        block
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {i18next.t("submit")}
                      </Button>
                    </Col>
                  )}
                  {isSubmitting && <Spinner color="success" />}
                </Form>
              )}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.toggleFavComment} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.setState({ toggleFavComment: false })}>
            {i18next.t("Comment")}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                comment: this.state.favComment,
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={(data, actions) => {
                if (this.state.favComment !== "") {
                  this.setState({ submitting: true });
                  var postData = {
                    domain: this.state.favouriteDomainSelected,
                    comment: this.state.favComment
                  };

                  favouriteService.setFavouriteComment(postData).then(
                    (response) => {
                      this.getFavourites();
                      this.setState({ submitting: false, toggleFavComment: false });
                    },
                    (error) => {
                      this.setState({ submitting: false });
                    }
                  );
                } else {
                  actions.setSubmitting(false);
                }
              }}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form action="" method="post" className="messageFormFav">

                  <FormGroup>
                    <Input
                      type="input"
                      id="favComment"
                      name="favComment"
                      placeholder={i18next.t("Comment")}
                      onChange={(e) =>
                        this.setFavComment(e)
                      }
                      value={this.state.favComment}
                      invalid={errors.comment && touched.comment}
                      valid={this.state.comment}
                    />
                    <FormFeedback>{errors.comment}</FormFeedback>
                  </FormGroup>

                  {!isSubmitting && (
                    <Col sm="12" md="3" style={{ padding: 0 }}>
                      <Button
                        className="cardButton"
                        block
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {i18next.t("submit")}
                      </Button>
                    </Col>
                  )}
                  {isSubmitting && <Spinner color="success" />}
                </Form>
              )}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.toggleFavDelete} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.setState({ toggleFavDelete: false })}>
            {i18next.t("delete")}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={(data, actions) => {
                this.setState({ submitting: true });
                var config = {
                  method: "get",
                  url: apiBaseUrl + '/Search/RemoveFavouriteSource/' + this.state.favouriteDomainSelected,
                  headers: {},
                };
                axios(config).then(() => {
                  this.getFavourites();
                  this.setState({ submitting: false, toggleFavDelete: false });
                });
              }}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form action="" method="post" className="messageFormFav">

                  <FormGroup>
                    <FormFeedback>{errors.comment}</FormFeedback>
                  </FormGroup>

                  <Col style={{ paddingBottom: '20px' }}>
                    {i18next.t("Do you really want to remove this source from favourites?")}
                  </Col>

                  {!isSubmitting && (
                    <Col sm="12" md="3">
                      <Button
                        className="cardButton"
                        block
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {i18next.t("delete")}
                      </Button>
                    </Col>
                  )}
                  {isSubmitting && <Spinner color="success" />}
                </Form>
              )}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.product.profile
});

export default connect(mapStateToProps)(Profile);
