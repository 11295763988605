import React from 'react';
// Components
import HomePage1 from './HomePage1';
// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// Styles
import '../index.scss';

require('dotenv').config({path: "\.env"});
const valueStripe = process.env.REACT_APP_STRIPE_PUB_KEY;
const stripePromise = loadStripe(valueStripe);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <HomePage1/>
    </Elements>
  );
}

export default App;
