import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from "react";
import Subscription from './Subscription';
import i18next from "i18next";
import { Button} from "reactstrap";

const Failure = () => {
  return (
    <div
    className="app  align-items-center jumbotron d-flex align-items-center"
    style={{
      backgroundImage: "url(/assets/img/doctors1.jpg)",
      backgroundSize: "cover"
    }}
  >
<br></br>
<br></br>
  <center><h2 style={{ textAlign: "center", fontSize: "35px" ,color: "#043cac" }}>{i18next.t("operationFailed")}{/*Operation failed! */}</h2>
  <br></br>
  <h2 style={{ textAlign: "center", fontSize: "35px" ,color: "#043cac" }}>{i18next.t("press2Return")}{/* Press the below button to return to the Subscription page, to retry. */}</h2>
  <br></br>
  <Button
                style={{ backgroundColor: "#09327e", color: "#fff" }}
                onClick={(event) => (window.location.href = "./Subscription")}
              >
                {i18next.t("return2Sub")}{/* Return to Subscribe */}

  </Button>
  </center>

    </div>


 );
};

export default Failure;
