import React, {useState} from 'react';
import axios from 'axios';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe} from '@stripe/react-stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';
//import { useHistory } from "react-router-dom";
//from the below the axios posts, puts and gets from and to the database
import {userService} from '../../../../_services/user.service.js'
import i18next from "i18next";




const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};



const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function HomePage1() {

 const classes = useStyles();
  // State
  const [subs, setSubs] = useState("");
  const [email, setEmail] = useState('');

  // setting up the SubscriptionID
  const [subsID, setSubsID] = useState("");

   // setting up the SubscriptionID1
   //const [subsID1, setSubsID1] = useState("");

//to redirect
  //const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();
  //in case the email is taken from registeration, it is being stored in localStorage
  //var email1 = localStorage.getItem("UserInfoEmail");


  //Unsubscribe function that handshakes with BE express server
   async function unsub(subscriptionId) {
    const res = await axios.post('http://localhost:3500/unsub', { 'subscriptionId': subscriptionId, });
    const feedback_success = res.status_unsub;
    {if (feedback_success === "succeed" )
    { console.log("Dima you have deleted the subscription!!!" + feedback_success); window.location.href= "/SucUnsub";
     /*history.push("/SucUnsub");*/ }

    // this is possible if the subscriber has unsubscribed and not updated in the DB StripeSubscription
    else{console.log('something went wrong! ERROR'); window.location.href= "/Failure";
    /*history.push("/Failure"); */}
    }
  }

//Function to update the SubscriptionStatus
  async function updateDetails(email){
    await userService.updateUnsubStatus(email).then( response => {console.log("Unsubscribe status updated to 1 "+ response);})
    console.log("this is just a stopper!");
  }

//function to get the SubscriptionID from the database and then send it to express server to unsubscribe and then update the SubscriptionID in the database
  async function getDetails(email){
    await userService.unsubscribe(email).then( response => {console.log("The feedback is "+ response.email+" "+ response.date+ " " + response.subscriptionId);
    if(response.subscriptionStatus === 0){unsub(response.subscriptionId); updateDetails(email);}
    else {console.log("Subscription already deleted!!!" ); window.location.href = "/alreadyUnSub";
    /* history.push("/alreadyUnSub");*/ }
})}

//function to post the subscriber data in the database as soon as subscribed
  async function PostDatabase(email,subID){await userService.payment(email,subsID).then(response => {console.log(response)})}

  async function handleSubmitUnSub(event)
  {
    if (!stripe || !elements) { return; }

    await userService.checkAvailability(email).then(response => { if(response==="Empty"){window.location.href = "/NotSubscribedYet";
      /* history.push("/NotSubscribedYet");*/ console.log("the response of check is "+ response);}
    else if( response === "Exists"){getDetails(email);}

  }
  )
  }
// what happens when the subscribe button is pushed
  async function handleSubmitSub(event) {


    if (!stripe || !elements) { return; }
   await userService.checkAvailability(email).then(response => {if(response==="Exists"){const newLocal = "/alreadySubscribed";
   window.location.href = newLocal;
   /*history.push(newLocal)*/;return false;}})


    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });


    if (result.error) {
      console.log(result.error.message);

    }
    else
     {

      const res = await axios.post('http://localhost:3500/sub',
      { 'payment_method': result.paymentMethod.id, 'email': email });

      // eslint-disable-next-line camelcase
      const client_secret = res.client_secret
      const status= res.status
      const subscriptionId = res.subscriptionId
      console.log("SubscriptionId is "+subscriptionId);
      setSubsID(subscriptionId);




      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function (result) {if (result.error) {setSubs("one");console.log('There was an issue!');console.log(result.error);}
         else {setSubs('two');console.log('You got the money with an action needed!');}
        });}
      else { setSubs('two'); console.log('You got the money with no action needed!');}


    }

  }
  if(subs ==="one"){console.log("final stage you will be redirected to failure since substripe is " +subs);
  window.location.href = "/Failure";
 /* history.push("/Failure");*/}
  else if(subs ==="two"){PostDatabase(email,subsID);console.log("final stage you will be redirected to success since substripe is " +subs);
  window.location.href ="/Success";
  /*history.push("/Success");*/ }

  return (


    <Card className={classes.root}>
      <br></br>
      <h2 style={{ textAlign: "center", fontSize: "40px" ,color: "#043cac" }}>DDH Subscription</h2>
      <CardContent className={classes.content}>
        <TextField
          label='Email'
          id='outlined-email-input'
          helperText={`Please enter the same email registered with to DDH`}
          margin='normal'
          variant='outlined'
          type='email'
          required
          //value={email1}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <CardInput />
        <div className={classes.div}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitSub}>
            Subscribe
          </Button>
          <Button
                variant="contained" color="primary" className={classes.button}
                onClick={(event) => (window.location.href = "./login")}
              >
                Return to Login
              </Button>
              <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitUnSub}>
            Unubscribe
            </Button>
        </div>
      </CardContent>
    </Card>


  );
}

export default HomePage1;
