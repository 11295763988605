import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from "react";
import { Container } from '@material-ui/core';


const Subscription = () => {
  return (
    <div
    className="app  align-items-center jumbotron d-flex align-items-center"
    style={{
      backgroundImage: "url(/assets/img/doctors1.jpg)",
      backgroundSize: "cover"
    }}
  >

  <Container>
  <App/>
  </Container>

    </div>


 );
};

export default Subscription;
