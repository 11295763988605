import React from "react";
//import { Button} from "reactstrap";
import Button from '@material-ui/core/Button';
import i18next from "i18next";

const NotSubscribedYet = () => {
  return (
    <div
      className="app  align-items-center jumbotron d-flex align-items-center"
      style={{
        backgroundImage: "url(/assets/img/doctors1.jpg)",
        backgroundSize: "cover"
      }}
    >
      <br></br>
      <br></br>
      <center><h2 style={{
        textAlign: "center",
        fontSize: "35px",
        color: "#043cac"
      }}>{i18next.t("youHaveNoteYetSubscribed2DDH")}{/*You have not yet subscribed to DDH */}</h2>
        <br></br>
        <h2 style={{
          textAlign: "center",
          fontSize: "35px",
          color: "#043cac"
        }}>{i18next.t("press2Return2Sub")}{/* Press the below button to return to subscribe.*/}</h2>
        <br></br>

        <Button style={{backgroundColor: "#09327e", color: "#fff"}}
                onClick={(event) => (window.location.href = "./Subscription")}>
          {i18next.t("return2Sub")}{/* Return to Subscribe */}
        </Button>
      </center>

    </div>


  );
};

export default NotSubscribedYet;
