import React from "react";
//import { Button} from "reactstrap";
import Button from '@material-ui/core/Button';


const alreadySubscribed = () => {
  return (
    <div
      className="app  align-items-center jumbotron d-flex align-items-center"
      style={{
        backgroundImage: "url(/assets/img/doctors1.jpg)",
        backgroundSize: "cover"
      }}
    >
      <br></br>
      <br></br>
      <center><h2 style={{textAlign: "center", fontSize: "35px", color: "#043cac"}}>You are already Subscribed!</h2>
        <br></br>
        <h2 style={{textAlign: "center", fontSize: "35px", color: "#043cac"}}>Press the below button to return to the
          login page.</h2>
        <br></br>

        <Button style={{backgroundColor: "#09327e", color: "#fff"}}
                onClick={(event) => (window.location.href = "./login")}>
          Return to Login
        </Button>
      </center>

    </div>


  );
};

export default alreadySubscribed;
